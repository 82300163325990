import React from "react";
import styled from "styled-components";
import { media } from "../../../constants/theme";
import { JobListingsAdDesktop } from "../ads/job-listings-desktop-ad";

const Wrapper = styled.div`
  display: none;

  ${media.desktop`
    display:block;   
    position:sticky; 
    top: 24px;
  `}
`;

export const Partner = () => {
  return (
    <Wrapper>
      <JobListingsAdDesktop />
    </Wrapper>
  );
};
